import { useEffect } from 'react';
import MainLayout from "../../layouts/MainLayout";

const Terms = () => {
  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode') === 'true';
    document.documentElement.classList.toggle('dark', storedDarkMode);
  }, []);
  return (
    <MainLayout>
      <div className="flex flex-col justify-center mx-auto w-full max-w-6xl mt-50 p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
      <h1 className="mb-4 font-bold text-22">Terms and Conditions</h1>
      <p className="leading-relaxed mb-2">Effective Date: December 1, 2024</p>
        <p className="leading-relaxed mb-2">
          These Terms and Conditions govern your use of{' '}
          <a
            href="https://www.paralegal.lk"
            className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            paralegal.lk
          </a>
          legal search engine platform and the subscription services provided. 
          By accessing or using our platform, you agree to comply with these terms. 
          Please read them carefully before proceeding with any transactions or usage.
        </p>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Use of the Platform</h2>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
            You must be at least 18 years old to use our platform or subscribe to our services.
          </li>
          <li>
            You are responsible for maintaining the confidentiality of your account credentials, including your username and password.
          </li>
          <li>
            You agree to provide accurate and current information during registration and subscription.
          </li>
          <li>
            You may not use our platform for any unlawful or unauthorized purposes, including but not limited to violating Sri Lankan laws or infringing on third-party rights.
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Subscription and Payments</h2>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
            By subscribing to our services, you agree to an annual subscription plan.
          </li>
          <li>
            Subscription fees are billed annually in advance. Prices are subject to change upon renewal with prior notice.
          </li>
          <li>
            You agree to provide valid and up-to-date payment information and authorize us to charge the total subscription fee, including any applicable taxes, to your chosen payment method.
          </li>
          <li>
            We use trusted third-party payment processors to handle your payment information securely. We do not store your full payment details.
          </li>
          <li>
            If your payment fails, your access to the platform may be suspended until payment is successfully processed.
          </li>
          <li>
            If we have cause to believe that you are sharing your subscription account with multiple users, we reserve the right to suspend your subscription or take other suitable steps as necessary. 
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Subscription Management and Cancellations</h2>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
            <strong>Cancellations: </strong> If you cancel within the first 30 days, you may be eligible for a refund as outlined in our Refund Policy.
          </li>
          <li>
            <strong>Renewals: </strong> Subscriptions automatically renew annually unless canceled prior to the renewal date.
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Service Availability and Updates</h2>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
            We strive to provide uninterrupted access but do not guarantee the platform will be available at all times.
          </li>
          <li>
            We reserve the right to modify or discontinue features, services, or the platform itself without notice.
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Intellectual Property</h2>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
            All content on our platform, including text, data, algorithms, logos, and graphics, are the properties of {' '}
            <a
            href="https://www.paralegal.lk"
            className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            paralegal.lk
          </a> a {' '}
            or its licensors and is protected by intellectual property laws.
          </li>
          <li>
            You may not copy, reproduce, distribute, or create derivative works from any content without our prior written consent.
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Returns and Refunds</h2>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
            You agree not to:
            <ul className="list-inside leading-relaxed mb-2 [text-indent:0.5rem]">
              <li>- Attempt to reverse-engineer or disrupt the platform’s functionality.</li>
              <li>- Use automated systems (e.g., bots) to access the platform without our express permission.</li>
              <li>- Share your login credentials or allow multiple users to access your account.</li>
            </ul>
          </li>
          <li>
            Your failure to comply with these conditions will result in the immediate suspension of your account and further legal action. 
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Limitation of Liability</h2>
        <ul>
          <li>
            {' '}
            <a
            href="https://www.paralegal.lk"
            className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            paralegal.lk
          </a>
            , its directors, employees, and affiliates shall not be liable for any direct, indirect, incidental, or consequential damages arising from your use of the platform.
          </li>
          <li>
            We do not guarantee the accuracy, reliability, or completeness of the legal information provided on the platform. It is intended for informational purposes only and does not constitute legal advice.
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Amendments and Termination</h2>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
            We reserve the right to update or terminate these Terms and Conditions at any time without prior 
            notice. Continued use of the platform after changes are posted constitutes acceptance of the revised terms.
          </li>
          <li>
            We may suspend or terminate your access if you violate these terms.
          </li>
        </ul>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Governing Law and Dispute Resolution</h2>
        <ul className="list-disc list-inside leading-relaxed mb-2 [text-indent:-1.5rem] [padding-left:3.5rem]">
          <li>
            These Terms and Conditions are governed by the laws of Sri Lanka.
          </li>
          <li>
            Any disputes arising from the use of the platform shall be resolved through arbitration or a suitable legal proceeding in Sri Lanka.
          </li>
        </ul>
        <p className="mb-4 mt-6 leading-relaxed">
          For any questions or concerns regarding these Terms and Conditions, please contact us at  <a href="mailto:admin@paralegal.lk" className="hover:underline text-pl_prim_color_1">admin@paralegal.lk</a>.
        </p>
        <h2 className="md:mb-1 mt-4 md:mt-6 font-semibold text-16 md:text-18">Paralegal (Pvt) Ltd</h2>
        <p className="leading-relaxed mb-2">
            <strong>
                95, Chemmani Road,<br />
                Nallur,<br />
                Jaffna 40000
            </strong>
        </p>
      </div>
    </MainLayout>
  );
};

export default Terms;
