import React, { memo, useRef, useState, useEffect } from 'react';
import DropDown from './DropDown/DropDown';
import { SearchBox } from 'react-instantsearch';
import { geminiInput } from '../../services/genAI_autocomplete';
import { useDebouncedCallback } from 'use-debounce';
import { useSelector } from 'react-redux';
import { getRandomQueries, QUERY_LIST } from './query_list';

const SearchBar = memo(({ size, isDropdown = false }) => {
  const suggestionsRef = useRef([]);
  const containerRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [highlightIndex, setHighlightIndex] = useState(-1);
  const [hasGeminiFetched, setHasGeminiFetched] = useState(false);
  const searchType = sessionStorage.getItem('searchType');
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const currentPage = useSelector((state) => state.home.currentPage);
  let handleSuggestion = false;

  const filterInitialSuggestions = (query) => {
    if (query.trim() !== '') {
      const searchQuery = query.slice(0, 2).toLowerCase();
      const filteredSuggestions = QUERY_LIST.filter((suggestion) =>
        suggestion.toLowerCase().startsWith(searchQuery.toLowerCase())
      ).slice(0, 5);

      // Fill up to 5 suggestions with random ones if needed
      if (filteredSuggestions.length < 5) {
        const additionalSuggestions = getRandomQueries().filter(
          (randomSuggestion) =>
            !filteredSuggestions.includes(randomSuggestion) &&
            !randomSuggestion.toLowerCase().startsWith(query.toLowerCase())
        );

        suggestionsRef.current = [
          ...filteredSuggestions,
          ...additionalSuggestions.slice(0, 5 - filteredSuggestions.length),
        ];
      } else {
        suggestionsRef.current = filteredSuggestions;
      }
    } else {
      suggestionsRef.current = getRandomQueries();
    }
  };

  const handleInput = (event) => {
    const newQuery = event.target.value;
    setSearchQuery(newQuery);
    setHighlightIndex(-1);
    if (newQuery === '') {
      setHasGeminiFetched(false);
    } else {
      debouncedFetchSuggestions(newQuery);
    }
    if (!hasGeminiFetched) {
      filterInitialSuggestions(newQuery);
    }
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const inputElement = event.target.querySelector('input');
    const inputValue = inputElement.value;
    // const processedQuery = processQuery(inputValue);
    sessionStorage.setItem('isEmpty', false);
    sessionStorage.setItem('searchedQuery', inputValue);
    if (inputValue) {
      newSearch(inputValue);
    }
  };

  const handleReset = () => {
    sessionStorage.setItem('isEmpty', true);
    sessionStorage.removeItem('searchedQuery');
  };

  const debouncedFetchSuggestions = useDebouncedCallback(async (query) => {
    if (query.trim() !== '') {
      const response = await geminiInput(query);
      suggestionsRef.current = response;
      setHasGeminiFetched(true);
    } else {
      // suggestionsRef.current = [];
    }
  }, 300);

  const handleSuggestionClick = (suggestion) => {
    // const processedQuery = processQuery(suggestion);
    sessionStorage.setItem('isEmpty', false);
    sessionStorage.setItem('searchedQuery', suggestion);
    handleSuggestion = true;
    if (suggestion) {
      newSearch(suggestion);
    }
  };

  const newSearch = (query) => {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    if (searchType === 'Exact Match') {
      if (query && !(query.startsWith('"') && query.endsWith('"'))) {
        query = `"${query.replace(/"/g, '')}"`;

        params.set('paralegal[query]', query);

        const newUrl = `/search?${params.toString()}`;

        window.location.href = newUrl;
      }
    } else {
      if (searchType === 'All' && currentPage === 'home') {
        params.set('paralegal[query]', query);

        const newUrl = `/search?${params.toString()}`;

        window.location.href = newUrl;
      } else if (searchType === 'Case Number') {
        if (query !== null) {
          let _query = query?.replace(/ /g, '-');
          params.set('paralegal[query]', _query);

          const newUrl = `/search?${params.toString()}`;

          window.location.href = newUrl;
        }
      } else {
        if (handleSuggestion === true) {
          params.set('paralegal[query]', query);

          const newUrl = `/search?${params.toString()}`;

          window.location.href = newUrl;
        }
      }
    }
  };

  const handleKeyDown = (event) => {
    if (suggestionsRef.current.length === 0) return;

    if (event.key === 'ArrowDown') {
      setHighlightIndex((prevIndex) =>
        prevIndex < suggestionsRef.current.length - 1 ? prevIndex + 1 : 0
      );
      event.preventDefault();
    } else if (event.key === 'ArrowUp') {
      setHighlightIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : suggestionsRef.current.length - 1
      );
      event.preventDefault();
    } else if (event.key === 'Enter') {
      if (
        highlightIndex >= 0 &&
        highlightIndex < suggestionsRef.current.length
      ) {
        handleSuggestionClick(suggestionsRef.current[highlightIndex]);
        event.preventDefault();
      }
      setSearchQuery('');
      // suggestionsRef.current = [];
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setSearchQuery('');
        // suggestionsRef.current = [];
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      ref={containerRef}
      className={`${
        size === 'small'
          ? 'justify-start h-50'
          : 'justify-center px-5 sm:px-10 h-50 md:h-60'
      } @container h-50 max-w-[800px] items-center w-full`}
    >
      <div
        className={`relative z-[100] flex h-full items-center dark:text-pl_txt_dark_1 bg-pl_bg_light_2 dark:bg-pl_bg_dark_2 rounded-30 px-4 ${
          size === 'small'
            ? 'bottom-0'
            : 'border border-pl_border_light_1 dark:border-pl_border_dark_1'
        }`}
      >
        {isDropdown && (
          <div className="flex pr-10 mr-10 border-r border-gray-400">
            <DropDown size="small" />
          </div>
        )}
        <SearchBox
          placeholder={
            isDesktop
              ? 'Start typing a legal query, key word, party name, or case number'
              : 'Type a legal query or key word'
          }
          searchAsYouType={false}
          onSubmit={handleFormSubmit}
          onInput={handleInput}
          onKeyDown={handleKeyDown}
          classNames={{
            root: 'flex-1 ',
            input:
              'bg-transparent ps-1 md:ps-2 py-2 pe-9 w-full md:text-16 text-14 focus:outline-none flex-1 placeholder-pl_txt_light_2 dark:placeholder-pl_txt_dark_2',
            form: 'flex w-full relative',
            loadingIcon: 'flex h-20 w-20 absolute right-16 top-2 md:top-2.5',
          }}
          resetIconComponent={({ classNames }) => (
            <div
              onClick={handleReset}
              className={`${classNames.resetIcon} absolute right-16 top-1.5 md:top-2`}
            >
              <svg
                className="text-pl_txt_light_2 dark:text-pl_txt_dark_1"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          )}
          submitIconComponent={({ classNames }) => (
            <div className={`${classNames.submitIcon}`}>
              <div className="flex justify-center items-center me-2">
                <div className="h-30 border-l border-pl_border_light_2 dark:border-pl_border_dark_2 pe-4"></div>
                <svg
                  className="text-pl_txt_light_2 dark:text-pl_txt_dark_1"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.42857 0.0669708C9.39875 0.0669708 11.2882 0.849621 12.6814 2.24275C14.0745 3.63588 14.8571 5.52536 14.8571 7.49554C14.8571 9.33554 14.1829 11.027 13.0743 12.3298L13.3829 12.6384H14.2857L20 18.3527L18.2857 20.067L12.5714 14.3527V13.4498L12.2629 13.1413C10.96 14.2498 9.26857 14.9241 7.42857 14.9241C5.45839 14.9241 3.56891 14.1415 2.17578 12.7483C0.782651 11.3552 0 9.46572 0 7.49554C0 5.52536 0.782651 3.63588 2.17578 2.24275C3.56891 0.849621 5.45839 0.0669708 7.42857 0.0669708ZM7.42857 2.35269C4.57143 2.35269 2.28571 4.6384 2.28571 7.49554C2.28571 10.3527 4.57143 12.6384 7.42857 12.6384C10.2857 12.6384 12.5714 10.3527 12.5714 7.49554C12.5714 4.6384 10.2857 2.35269 7.42857 2.35269Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          )}
        />
        {searchQuery.trim() !== '' && suggestionsRef.current.length > 0 && (
          <div
            className={`absolute z-[100] w-full left-0 px-1 ${
              size === 'small' ? 'top-[54px]' : 'top-[54px] md:top-16'
            }`}
          >
            <div className="z-[100] text-14 dark:text-pl_txt_dark_1 bg-pl_bg_light_5 dark:bg-pl_bg_dark_3  rounded-2xl shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden w-full">
              {suggestionsRef.current.map((suggestion, index) => (
                <div
                  key={index}
                  className={`w-full px-3 md:px-4 py-2 md:py-3 text-left cursor-pointer ${
                    index === highlightIndex
                      ? 'bg-pl_bg_light_2 dark:bg-pl_bg_dark_2'
                      : 'hover:bg-pl_bg_light_2 hover:dark:bg-pl_bg_dark_2'
                  }`}
                  onClick={() => handleSuggestionClick(suggestion)}
                >
                  {suggestion}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default SearchBar;
