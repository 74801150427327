import { useAuthContext } from '@asgardeo/auth-react';
import { useEffect } from 'react';
import { usePagination } from 'react-instantsearch';

function CustomPagination(props) {
  const { currentRefinement, nbPages, isFirstPage, isLastPage, refine } =
    usePagination(props);

  const { state, signIn } = useAuthContext();

  useEffect(() => {
    // Ensure the first page is selected when the component mounts
    if (currentRefinement === undefined && nbPages > 0) {
      refine(0);
    }
  }, [currentRefinement, nbPages, refine]);

  const handlePageClick = (page) => {
    if (page > 0) {
      if (!state.isAuthenticated) {
        signIn();
      } else {
        if (page !== currentRefinement) {
          refine(page);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    } else {
      if (page !== currentRefinement) {
        refine(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
  };

  const renderPageNumbers = () => {
    const visiblePages = [];
    const range = 1; // Number of pages to display before/after the current page

    let start = Math.max(currentRefinement - range, 0);
    let end = Math.min(currentRefinement + range, nbPages - 1);

    // Add the first page if it's not in the visible range
    if (start > 0) {
      visiblePages.push(1);
      if (start > 1) visiblePages.push('...'); // Add ellipsis if there are pages before the current range
    }

    // Push page numbers in the visible range
    for (let i = start; i <= end; i++) {
      visiblePages.push(i + 1); // Convert to 1-based index for display
    }

    // Add ellipsis if the last pages are not in the visible range
    if (end < nbPages - 3) {
      visiblePages.push('...');
      visiblePages.push(nbPages - 1);
      visiblePages.push(nbPages);
    } else if (end < nbPages - 2) {
      visiblePages.push(nbPages - 1);
      visiblePages.push(nbPages);
    } else if (end < nbPages - 1) {
      visiblePages.push(nbPages);
    }

    return visiblePages;
  };

  return (
    <>
      {renderPageNumbers().length > 0 && (
        <div className="flex font-poppins text-14 items-center justify-center space-x-1 sm:space-x-2">
          <button
            onClick={() => handlePageClick(currentRefinement - 1)}
            disabled={isFirstPage}
            className="flex justify-center items-center gap-5 md:px-4 py-2 border-0 text-pl_prim_color_1 rounded-md cursor-pointer disabled:text-pl_txt_light_3 dark:disabled:text-pl_txt_dark_3 disabled:cursor-not-allowed"
          >
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.1666 8.06694H3.83325M3.83325 8.06694L8.49992 12.7336M3.83325 8.06694L8.49992 3.40027"
                stroke="currentColor"
                strokeOpacity="0.8"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="md:flex hidden">Previous</div>
          </button>

          {renderPageNumbers().map((page, index) => (
            <button
              key={index}
              onClick={() => handlePageClick(page - 1)}
              className={`h-25 min-w-25 px-1 flex items-center justify-center rounded cursor-pointer text-pl_prim_color_1 ${
                page === currentRefinement + 1
                  ? 'bg-pl_prim_color_1 text-white opacity-80'
                  : ''
              }`}
            >
              <div>{page}</div>
            </button>
          ))}

          <button
            onClick={() => handlePageClick(currentRefinement + 1)}
            disabled={isLastPage}
            className="flex justify-center items-center gap-5 md:px-4 py-2 border-0 text-pl_prim_color_1 rounded-md cursor-pointer disabled:text-pl_txt_light_3 dark:disabled:text-pl_txt_dark_3 disabled:cursor-not-allowed"
          >
            <div className="md:flex hidden">Next</div>
            <svg
              width="17"
              height="17"
              viewBox="0 0 17 17"
              fill="none"
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.06694H13.1666M13.1666 8.06694L8.49992 3.40027M13.1666 8.06694L8.49992 12.7336"
                stroke={isLastPage ? '#616161' : '#df0d7d'}
                strokeOpacity="0.8"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      )}
    </>
  );
}

export default CustomPagination;
