import React, { useEffect, useState } from 'react';
import HitSkeleton from './Hit/HitSkeleton';
import RefinementListSkeleton from './RefinementListCard/RefinementListSkeleton';

const ResultsSkeleton = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="container relative">
      <div className="flex flex-col py-20 pt-[200px] md:pt-[150px] max-w-6xl">
        <div className="flex w-full md:gap-60 animate-pulse">
          <div className="flex w-3/4 px-6 max-w-[800px]">
            <div className="h-2 bg-pl_bg_light_4 dark:bg-pl_bg_dark_4 opacity-100 rounded-full w-1/3"></div>
          </div>
          <div className="flex gap-10 w-1/5">
            <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-20 rounded-full w-1/3"></div>
          </div>
        </div>

        {/* Main Content: InfiniteHits and Right-Side Filters */}
        <div className="flex w-full md:gap-60">
          <div className="md:w-3/4 w-full flex flex-col  max-w-[800px]">
            <div className="skeleton-container">
              {Array.from({ length: 15 }).map((_, index) => (
                <HitSkeleton key={index} />
              ))}
            </div>
          </div>

          {isDesktop && (
            <div className="flex w-1/5">
              <RefinementListSkeleton />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultsSkeleton;
