import React from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../Logo/Logo';
import SigninButton from "./SigninButton/SigninButton";
import MenuCard from "./MenuCard/MenuCard";
import UserProfile from "./UserProfile/UserProfile";
import { useAuthContext } from "@asgardeo/auth-react";

const LogoHeader = ({showMenu = true}) => {
  const { state } = useAuthContext();
  const navigate = useNavigate();
  return (
    <div className="w-full bg-pl_bg_light_1 dark:bg-pl_bg_dark_3 border-y dark:border-pl_border_dark_1 border-pl_border_light_1 p-10 md:py-15 md:px-20 fixed z-[100]">
      <div className="flex flex-wrap items-center justify-between">
        <button
          onClick={() => navigate('/')}
          className="flex-none items-center gap-20"
        >
          <Logo small />
        </button>
        {showMenu && (
          <div
            className="flex gap-3 items-center ml-auto md:pb-0 pl-10"
          >
            <MenuCard />
            {state.isAuthenticated ? <UserProfile /> : <SigninButton />}
          </div>
        )}
      </div>
      
    </div>
  );
};

export default LogoHeader;
