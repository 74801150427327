import React from 'react';

const HitSkeleton = () => {
  return (
    <div className="flex flex-col p-6 pb-1 rounded-md mb-5 animate-pulse">
      <div className="flex items-center gap-30">
        <div className="h-2 bg-pl_bg_light_4 dark:bg-pl_bg_dark_4 opacity-100 rounded-full w-[150px]"></div>
        <div className="h-2 bg-pl_bg_light_4 dark:bg-pl_bg_dark_4 opacity-90 rounded-full w-[80px]"></div>
      </div>

      <div className="flex gap-5 mt-4">
        <div className="h-2 bg-pl_bg_light_4 dark:bg-pl_bg_dark_4 opacity-90 rounded-full w-[80px]"></div>
        <div className="h-2 bg-pl_bg_light_4 dark:bg-pl_bg_dark_4 opacity-100 rounded-full w-full"></div>
      </div>

      <div className="flex gap-5 mt-4">
        <div className="h-2 bg-pl_bg_light_4 dark:bg-pl_bg_dark_4 opacity-100 rounded-full w-[80px]"></div>
        <div className="h-2 bg-pl_bg_light_4 dark:bg-pl_bg_dark_4 opacity-90 rounded-full w-full"></div>
      </div>

      <div className="mt-4">
        <div className="flex gap-5">
          <div className="h-2 bg-pl_bg_light_4 dark:bg-pl_bg_dark_4 opacity-90 rounded-full w-[100px] mb-1"></div>
          <div className="h-2 bg-pl_bg_light_4 dark:bg-pl_bg_dark_4 opacity-100 rounded-full w-full"></div>
        </div>
        <div className="h-2 mt-2 bg-pl_bg_light_4 dark:bg-pl_bg_dark_4 opacity-90 rounded-full w-full"></div>
      </div>
    </div>
  );
};

export default HitSkeleton;
