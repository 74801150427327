import { useAuthContext } from "@asgardeo/auth-react";
import React, { useState, useEffect, useRef } from "react";

function UserProfile() {
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({ username: "", email: "" });
  const menuRef = useRef(null);
  const { state, signOut, getBasicUserInfo, getDecodedIDToken } =
    useAuthContext();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const info = await getBasicUserInfo();
        const moreInfo = await getDecodedIDToken();
        setUserInfo({
          username: info.displayName || info.username || "",
          email: info.email || moreInfo.email || "",
        });
      } catch (error) {
        console.error("Failed to fetch user info:", error);
      }
    };

    if (state.isAuthenticated) {
      fetchUserInfo();
    }
  }, [state.isAuthenticated, getBasicUserInfo, getDecodedIDToken, state]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative z-[110]">
      <button
        className="h-30 capitalize w-30 rounded-full font-semibold text-white bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] hover:bg-gradient-to-br hover:from-[#a6208e] hover:via-[#ca1582] hover:to-[#dd0c7e] transition duration-500 ease-in-out"
        onClick={toggleMenu}
      >
        {userInfo.username ? userInfo.username.charAt(0) : ""}
      </button>

      {isOpen && (
        <div
          ref={menuRef}
          className="absolute z-[110] right-0 mt-2 bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 rounded-10 shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden dark:text-pl_txt_dark_3 text-pl_txt_light_3"
        >
          <div className="px-10 whitespace-nowrap text-center py-10">
            <div className="w-full text-16 font-semibold capitalize">
              {userInfo.username}
            </div>
            <div className="text-14">
              {userInfo.email}
            </div>
          </div>
          <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" />

          <button
            className="flex items-center text-14 justify-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={() => signOut()}
          >
            <svg
              className="fill-current me-2"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
              fillOpacity="0.8"
            >
              <path d="M17 7L15.59 8.41L18.17 11H8V13H18.17L15.59 15.58L17 17L22 12M4 5H12V3H4C2.9 3 2 3.9 2 5V19C2 20.1 2.9 21 4 21H12V19H4V5Z" />
            </svg>
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

export default UserProfile;
