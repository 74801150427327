export const QUERY_LIST = [
  'Appointment or removal of directors cannot be treated as act of Oppression and Mismanagement',
  'Arbitration and Article 140 of the constitution',
  'Abdul Fareed Mohamed Munas Vs. Ameenathul Sureyan Razeed and others',
  'Acceptance of money contract binding by conduct',
  'Administrative law freedom of expression',
  'A waiver is the intentional relinquishment of a known right, which must be clear and unequivocal',
  'Administrative law command function',
  'Arbitration and Article 140 of the constitution',
  'Ancillary purpose',
  'Breach of sales agreement',
  'Broke the store, robbery, threat by knife',
  'Board of investment lease approval',
  'Breach of fundamental rights article 12,14 case',
  'Collective bargaining related Sri Lankan cases',
  'Conveyance of land to person other than the one to whom owner is under agreement to sell',
  'Can the state requisition private property for use by Cooperative Societies?',
  'ca writ 331',
  'Constructive termination',
  'Conveyance of land to person other than the one to whom owner is under agreement to sell',
  'Criminal breach of trust criminal misappropriation different offences',
  'Cannot make decisions without material before court',
  'Command and control function of administrative law',
  'Concealment in terms of the customs ordinance',
  'Confiscation of vehicle under forest',
  'Difference between section 109 and 110 of criminal procedure code',
  'Definition of state owned enterprise',
  'Debt recovery matters AG forwards inditement to High court, jurisdiction, section 393',
  'Doctrine of legitimate expectation based on promise',
  'Evidence must be confined to the matters in issue',
  'Failure to appeal amounts to acquiescence',
  'Fernando vs. Attorney General',
  'Fairness at every stage of disciplinary proceedings',
  'Firearms ordinance bail',
  'Forensic audit interim relief',
  'Grant of state land to private individuals',
  'Grounds for judicial review in administrative decisions',
  'General principles of natural justice',
  'How did commonwealth jurisdiction apply in postcolonial Sri Lanka?',
  'Hearing procedures in fundamental rights applications',
  "Hapugoda vs. People's Bank",
  'How habeas corpus writs are applied in Sri Lankan courts',
  'Irrelevant considerations in a writ',
  'Impact of illegality on enforceability of agreements',
  'Importance of intent in establishing criminal liability',
  'Injunctions and their application in labor disputes',
  'Issues related to improper use of public funds',
  'Interpretation of indemnity clauses in contracts',
  'Judicial review of administrative decisions',
  'Judicial discretion in granting bail',
  'Jayasinghe Vs. Ceylon Electricity Board',
  'Judgment in default of appearance in civil cases',
  'Joint venture agreements and legal implications',
  'Killing in self-defense and legal implications',
  'Kidnapping and abduction',
  'Knowledge of unlawful acts in criminal law',
  'Killing of a person by negligent driving',
  'Lease agreement, lease approval, board of investment',
  'Liquidated Damages for damage shall not limit or exclude any other remedies at law or in equity',
  'Land acquisition for public purposes',
  'Liability for defamation',
  'Labour disputes and termination',
  'Mistake of fact by counsel in written submissions in tax appeals commission matter',
  'Malicious intent to setting fire to property',
  'Mediation process in Sri Lanka’s civil disputes',
  'Muthusamy Vs. Bank of Ceylon',
  'Mistake of fact by counsel in written submission not to be considered',
  'Mistake of fact by counsel in written submissions',
  'Marginal notes interpretation',
  'Nullity of marriage',
  'Not to pay in a currency in which the contract was entered into would amount to an absence of consent',
  'Non-compliance with court orders consequences',
  'National security law and fundamental rights',
  'Omissions of words in a constitution',
  'Offence of contempt of court',
  'Ownership dispute in Sri Lankan property law',
  'Obligation of the state in enforcing fundamental rights',
  'Order of priority in debt recovery',
  'Proven facts if no cross examination',
  'Power of the President to dissolve Parliament',
  'Punishment for contempt of court',
  'Protection of minority rights',
  'Perera vs. The Commissioner of Labour',
  'Police powers under criminal law',
  'Quantum of compensation in labour tribunal compensation',
  'Qualifications for judges',
  'Questioning the constitutionality of laws',
  'Restriction of fundamental rights article 12,14 case',
  'Rejection of evidence in Sri Lankan criminal trials',
  'Right to remain silent in criminal investigations',
  'Rathnayake vs. Urban Development Authority',
  'Relevance of prior convictions in Sri Lankan courts',
  'Setting fire deliberately related Sri Lankan case laws',
  'State lands does not need to be utilized',
  'Section 51A of the Customs Ordinance',
  'Sexual harassment at the workplace',
  'sd 82',
  'Samarawickrama Vs. Minister of Public Administration',
  'Self-defense in criminal cases',
  'Sri Lanka’s constitution and the separation of powers',
  'Trade union rights and industrial disputes',
  'Termination of employment under labor law',
  'Taxation of non-resident Sri Lankans',
  'Terrorism-related offenses',
  'Time limitation for filing appeals',
  'Using consecutive fixed term contracts to circumvent benefits afforded to permanent employees',
  'Unregistered land transactions and their legal consequences',
  'Use of discretion by judicial officers',
  'Voluntary retirement scheme',
  'Victim of custody',
  "Violation of human rights in Sri Lanka's legal context",
  'Voting rights of dual citizens',
  'Validity of postal voting',
  'What is a delict',
  'Writ of certiorari in administrative law',
  'What constitute a breach of peace s66',
  'Women’s rights and legal protections ',
  'Water pollution and environmental regulations',
  'Wrongful termination of employment',
  'Wijesuriya vs. Lankem Ceylon Ltd.',
  'Witness protection and legal procedures',
  'Xenocide and international law related to Sri Lanka',
  'X-ray evidence admissibility',
  'Youth and criminal responsibility',
  'Youths in conflict with the law and rehabilitation',
  'Zero emission regulations and environmental law',
  'Zonal distribution of electoral seats',
  'Zakat and its legal implications',
  'Zero tolerance policies in Sri Lankan criminal law',
  '1982 v2 Sri LR 787',
  '2014 v1 Sri LR 1',
  '35 NLR 291',
  '4 NLR 296',
  '5-nlr-138',
  '6 NLR 802',
  '75 NLR 128',
  '8 NLR 338',
  '9 NLR 366',
];

export const getRandomQueries = () => {
  const shuffled = [...QUERY_LIST].sort(() => 0.5 - Math.random());
  return shuffled.slice(0, 5);
};
