export const transformItemsYear = (items) => {
  const sortedItems = items.sort(
    (a, b) => parseInt(b.value, 10) - parseInt(a.value, 10)
  );
  return sortedItems.slice(0, 89);
};

export const transformItemsMonth = (items) => {
  const monthOrder = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return items.sort((a, b) => {
    const aIndex = monthOrder.indexOf(a.value);
    const bIndex = monthOrder.indexOf(b.value);
    return aIndex - bIndex;
  });
};

export const transformItemsPageNum = (items) => {
  return items.sort((a, b) => parseInt(a.value, 10) - parseInt(b.value, 10));
};

export const transformItemsVolumeNum = (items) => {
  return items.sort((a, b) => {
    const volumeA = parseInt(a.label.replace(/\D+/g, ''), 10);
    const volumeB = parseInt(b.label.replace(/\D+/g, ''), 10);
    return volumeA - volumeB;
  });
};

export const transformItemsCourt = (items) => {
  return items.map((item) => {
    if (item.label === 'SC') {
      return { ...item, label: 'Supreme Court' };
    } else if (item.label === 'CA') {
      return { ...item, label: 'Court of Appeal' };
    }
    return item;
  });
};
