import { configureStore, createSlice } from '@reduxjs/toolkit';

const homeSlice = createSlice({
  name: 'home',
  initialState: {
    isAuth: false,
    isFocused: false,
    selectedOption: 'All',
    currentPage: '',
  },
  reducers: {
    setIsAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setIsFocused: (state, action) => {
      state.isFocused = action.payload;
    },
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
});

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export const homeActions = homeSlice.actions;

export default store;
