import React from "react";
import MainLayout from "../../layouts/MainLayout";

const Help = () => {
  return (
    <MainLayout>
      <div className="flex flex-col justify-center mx-auto max-w-6xl mt-50 p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
        <h1 className="mb-4 font-semibold text-18 md:text-22">Help</h1>
        <p className="leading-relaxed mb-2">
          By default, Paralegal uses a hybrid of exact matching and AI.
        </p>
        <p className="leading-relaxed mb-2">
          To match with exact names, keywords, or phrases, use 'Exact Search'.
        </p>
        <p className="leading-relaxed mb-2">
          When unsure about the exact query but you know terms of similar
          meaning, use 'AI Search'.
        </p>
        <p className="leading-relaxed mb-4">
          For fast and accurate matches on case references, use 'Case Number
          Search'.
        </p>
        <p className="leading-relaxed mb-2">
          Error, feedback, feature request:{" "}
          <a
            href="mailto:admin@paralegal.lk"
            className="hover:underline text-pl_prim_color_1"
          >
            admin@paralegal.lk
          </a>
        </p>
      </div>
    </MainLayout>
  );
};

export default Help;
