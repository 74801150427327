import React, { useState, useEffect } from "react";
import styled from "styled-components";

const Button = styled.button`
  display: inline-block;
  background-color: #dd0c7e;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  right: 30px;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  transition: background-color 0.3s, opacity 0.5s, visibility 0.5s;

  &::after {
    content: "▲";
    font-weight: normal;
    font-size: 1em;
    line-height: 30px;
    color: #fff;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &.show {
    opacity: 0.8;
    visibility: visible;
  }
`;

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);
  const [raiseButton, setRaiseButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowButton(true);
        if (
          window.scrollY + window.innerHeight >=
          document.documentElement.scrollHeight
        ) {
          setRaiseButton(true);
        }else{
          setRaiseButton(false);
        }
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return <Button className={`${showButton ? "show" : ""} ${raiseButton ? "bottom-[100px]" : "bottom-[30px]"}`} onClick={scrollToTop} />;
};

export default ScrollToTopButton;
