import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Benchmark from '../pages/Benchmark/Benchmark';
import Stats from '../pages/Stats/Stats';
import Results from '../pages/Results/Results';
import PageNotFound from '../pages/PageNotFound/PageNotFound';
import Privacy from '../pages/LegalPages/Privacy';
import Terms from '../pages/LegalPages/Terms';
import Refund from '../pages/LegalPages/Refund';
import Help from '../pages/Help/Help';
import About from '../pages/About/About';

const Router = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/help" element={<Help />} />
    <Route path="/benchmark" element={<Benchmark />} />
    <Route path="/stats" element={<Stats />} />
    <Route path="/about" element={<About />} />
    <Route path="/search" element={<Results />} />
    <Route path="/privacy" element={<Privacy />} />
    <Route path="/refund" element={<Refund />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="*" element={<PageNotFound />} />
  </Routes>
);

export default Router;
