import React from "react";
import MainLayout from "../../layouts/MainLayout";

const About = () => {
  return (
    <MainLayout>
      <div className="flex flex-col justify-center mx-auto max-w-6xl mt-50 p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_2">
        <h1 className="mb-4 font-semibold text-18 md:text-22">
          About{" "}
          <a
            href="https://www.paralegal.lk"
            className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            Paralegal.lk
          </a>
        </h1>
        <p className="leading-relaxed mb-4">
          <a
            href="https://www.paralegal.lk"
            className="font-bold bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text"
          >
            Paralegal.lk
          </a>{" "}
          is a comprehensive online platform dedicated to providing access to
          Sri Lankan legal resources. We specialize in case law research,
          offering over{" "}
          <span className="font-medium">20,000 appellate court decisions</span>{" "}
          dating back to <span className="font-medium">1880</span>, ensuring
          users can find relevant legal precedents efficiently.
        </p>
        <p className="leading-relaxed mb-4">
          Our advanced search features, including{" "}
          <span className="font-medium">Exact Search</span>,{" "}
          <span className="font-medium">AI Search</span>, and{" "}
          <span className="font-medium">Case Number Search</span>, facilitate
          quick and accurate retrieval of legal information.
        </p>
        <p className="leading-relaxed">
          We aim to support both legal professionals and the public by
          delivering current and relevant legal insights, enhancing
          understanding of Sri Lankan law.
        </p>
        <p className="leading-relaxed mt-4">
          For inquiries, please contact us at{" "}
          <a
            href="mailto:admin@paralegal.lk"
            className="hover:underline text-pl_prim_color_1"
          >
            admin@paralegal.lk
          </a>
        </p>
      </div>
    </MainLayout>
  );
};

export default About;
