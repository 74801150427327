import React from 'react';
import './RefinementListCard.css';

const RefinementListSkeleton = () => {
  return (
    <div className="flex flex-col gap-20 pt-10 md:pt-40 w-full dark:opacity-30">
      {Array(3)
        .fill(null)
        .map((_, index) => (
          <div key={index} className="flex flex-col gap-20">
            <div className="p-10 pl-0 pt-0 flex flex-col gap-15">
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-15 rounded-full w-4/6"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-10 rounded-full w-3/5"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-15 rounded-full w-3/5"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-10 rounded-full w-3/5"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-15 rounded-full w-3/5"></div>
            </div>
            <div className="p-10 pl-0 pt-0 flex flex-col gap-15">
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-15 rounded-full w-4/6"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-10 rounded-full w-3/5"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-15 rounded-full w-3/5"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-10 rounded-full w-3/5"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-15 rounded-full w-3/5"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-10 rounded-full w-3/5"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-15 rounded-full w-3/5"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-10 rounded-full w-3/5"></div>
              <div className="h-2 bg-gray-200 dark:bg-gray-500 opacity-15 rounded-full w-3/5"></div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default RefinementListSkeleton;
