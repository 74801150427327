import React from "react";
import packageJson from "../../../package.json";

const Copyright = () => {
  const year = new Date().getFullYear();
  return (
    <div className="text-right font-light py-5 gap-5 text-12 bg-pl_bg_light_1 dark:bg-pl_bg_dark_3 text-pl_txt_light_2 dark:text-pl_txt_dark_1">
      <div>
        Copyright &copy;{" "}
        <a
          href="https://www.paralegal.lk"
          target="_blank"
          rel="noopener noreferrer"
          className="text-pl_prim_color_1 font-medium hover:underline"
        >
          paralegal.lk
        </a>{" "}
        <span id="year">{year}</span> | All Rights Reserved.{" "}
        <div className="inline-block">Version: {packageJson.version}</div>
      </div>
    </div>
  );
};

export default Copyright;
