import React from 'react';
import { useDispatch } from 'react-redux';
import { homeActions } from '../../../store/store';
import { useLocation } from 'react-router-dom';

const Tabs = () => {
  const tabs = [
    'All',
    'Exact Match',
    'Semantic Match',
    'Case Number',
    'Parties',
  ];

  // const selectedTab = useSelector((state) => state.home.selectedOption);
  const _selectedTab = sessionStorage.getItem('searchType');

  const dispatch = useDispatch();
  const location = useLocation();

  const handleTabClick = (tab) => {
    dispatch(homeActions.setSelectedOption(tab));
    sessionStorage.setItem('searchType', tab);
    const queryParams = new URLSearchParams(location.search);
    const query =
      sessionStorage.getItem('searchedQuery') ||
      queryParams.get('paralegal[query]');
    const isEmpty = sessionStorage.getItem('isEmpty');

    if (isEmpty === 'false') {
      if (tab === 'Exact Match') {
        if (query && !(query.startsWith('"') && query.endsWith('"'))) {
          newSearch(`"${query.replace(/"/g, '')}"`);
        }
      } else if (query?.startsWith('"') && query.endsWith('"')) {
        newSearch(query.slice(1, -1));
      } else {
        newSearch(query);
      }

      if (tab === 'Case Number') {
        if (query !== null) {
          let _query = query;
          if (_query.startsWith('"') && _query.endsWith('"')) {
            _query = query.slice(1, -1);
          }
          newSearch(_query?.replace(/ /g, '-'));
        }
      }
    } else {
      //for rendering the state and apply the searchtype correctly if user click the searchType as 'All'
      if (tab === 'All') {
        newSearch('');
      }
    }
  };

  const newSearch = (query) => {
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);

    params.set('paralegal[query]', query);

    const newUrl = `/search?${params.toString()}`;

    window.location.href = newUrl;
  };

  // useEffect(() => {
  //   const queryParams = new URLSearchParams(location.search);
  //   const query = queryParams.get('paralegal[query]');

  //   if (query && query.startsWith('"') && query.endsWith('"')) {
  //     if (selectedTab !== 'Exact Match') {
  //       // dispatch(homeActions.setSelectedOption('Exact Match'));
  //       // sessionStorage.setItem('searchType', 'Exact Match');
  //     }
  //   } else {
  //     if (selectedTab === 'Exact Match') {
  //       // dispatch(homeActions.setSelectedOption('Exact Match'));
  //       // sessionStorage.setItem('searchType', 'Exact Match');
  //     } else {
  //       if (!sessionStorage.getItem('searchType')) {
  //         sessionStorage.setItem('searchType', 'All');
  //       }
  //     }
  //   }
  // }, []);

  return (
    <div className="flex items-center gap-20 px-10 md:px-20 text-13 text-pl_txt_light_2 dark:text-pl_txt_dark_1 font-normal dark:font-light overflow-x-auto">
      {tabs.map((tab) => (
        <div
          key={tab}
          className={`whitespace-nowrap py-5 px-1 ${
            _selectedTab === tab
              ? 'font-semibold border-b-2 dark:border-pl_txt_dark_1 border-pl_txt_light_2'
              : ''
          }`}
          onClick={() => handleTabClick(tab)}
          style={{ cursor: 'pointer' }}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
