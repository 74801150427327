import TypesenseInstantsearchAdapter from 'typesense-instantsearch-adapter';
import { typesenseServerConfig } from './typesense-server-config';

export function typesenseAdapter(queryBy, inFix, token) {
  const TYPESENSE_SERVER_CONFIG = typesenseServerConfig(token);
  const typesenseInstantsearchAdapter = new TypesenseInstantsearchAdapter({
    server: TYPESENSE_SERVER_CONFIG,
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  queryBy is required.
    additionalSearchParameters: {
      query_by: queryBy,
      infix: inFix,
      exclude_fields: 'summary_vec',
      sort_by: 'date:desc',
    },
  });
  return typesenseInstantsearchAdapter;
}
