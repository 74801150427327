import { typesenseAdapter } from './typesense-adapter';

export function searchClient(query_by, inFix, token) {
  const typesenseInstantsearchAdapter = typesenseAdapter(
    query_by,
    inFix,
    token
  );
  const searchClient = typesenseInstantsearchAdapter.searchClient;

  return searchClient;
}
