import React, { useEffect } from 'react';
import { useAuthContext } from '@asgardeo/auth-react';
import stopwords from '../../../utils/stopwords.json';
import './Hit.css';
import { useSelector } from 'react-redux';

const Hit = ({ hit }) => {
  const { state, signIn } = useAuthContext();
  const currentPage = useSelector((state) => state.home.currentPage);
  const highlightText = hit._highlightResult.decision_text.value;
  const keywords = hit.keywords || [];
  const legislation = hit.legislation || '';
  const keywordsLawsTerms = hit.keywords_laws_provisions_terms_ || '';
  const snippetText = hit._snippetResult?.decision_text?.value || '';

  const selectedOption = useSelector((state) => state.home.selectedOption);
  const searchType = selectedOption;

  const handleLinkClick = (event) => {
    if (!state.isAuthenticated) {
      event.preventDefault();
      sessionStorage.setItem('redirectToDocument', hit.link);
      const urlString = window.location.search;
      if (urlString) {
        sessionStorage.setItem('urlString', urlString);
      }
      signIn();
    }
  };

  useEffect(() => {
    if (state.isAuthenticated) {
      const redirectToDocument = sessionStorage.getItem('redirectToDocument');
      if (redirectToDocument) {
        window.open(redirectToDocument, '_blank');
        sessionStorage.removeItem('redirectToDocument');
      }
    }
  }, [state.isAuthenticated]);

  const formatDate = (date) => {
    const d = new Date(date);
    if (isNaN(d)) {
      console.error('Invalid date:', date);
      return '';
    }
    const day = d.getDate();
    const month = d.toLocaleString('default', { month: 'short' });
    const year = d.getFullYear();
    const suffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };

    return `${day}${suffix(day)} ${month} ${year}`;
  };

  const date = hit.date ? formatDate(hit.date) : '';

  const displayKeywords =
    keywordsLawsTerms.length > 0 ? keywordsLawsTerms : keywords.join(', ');

  const judges = hit.judge_final || [];
  const judgeText =
    judges.length > 0 && !(judges.length === 1 && judges[0] === 'NA') ? (
      <div className="mt-1 text-14 text-pl_txt_light_1 dark:text-pl_txt_dark_1">
        <span className="font-medium">
          {judges.length > 1 ? 'Judges:' : 'Judge:'}
        </span>{' '}
        {judges.join(', ')}
      </div>
    ) : null;

  const partiesText =
    hit.nameofparties && hit.nameofparties.trim() !== '' ? (
      <div className="mt-1">
        <span className="font-medium text-14 text-pl_txt_light_1 dark:text-pl_txt_dark_1">
          {hit.standard_casenumber.startsWith('sd')
            ? 'Special determination on:'
            : hit.standard_casenumber.includes('digest')
            ? 'Digest:'
            : 'Parties:'}
        </span>{' '}
        <span className="text-14 text-pl_txt_light_1 dark:text-pl_txt_dark_1">
          {hit.standard_casenumber.startsWith('sd')
            ? hit.nameofparties
            : displayNameOfParties(hit.nameofparties)}
        </span>
      </div>
    ) : null;

  return (
    <div className="flex flex-col px-6 pt-8">
      <div>
        <a
          href={hit.link}
          className="text-16 font-semibold text-pl_prim_color_1 hover:underline"
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleLinkClick}
        >
          {hit.standard_casenumber}
        </a>
        <span className="dark:text-pl_txt_dark_3 text-pl_txt_light_3 ml-6 text-12">
          {date}
        </span>
      </div>

      {partiesText}
      {judgeText}
      <div className="mt-1 text-pl_txt_light_1 dark:text-pl_txt_dark_1 text-14">
        <span className="font-medium">Keywords:</span>{' '}
        <span>{displayKeywords}</span>
      </div>
      {legislation && (
        <div className="mt-1 text-pl_txt_light_2 dark:text-pl_txt_dark_3 text-14">
          <span className="font-semibold">Legislation:</span>{' '}
          <span>{legislation}</span>
        </div>
      )}
      {currentPage !== 'benchmark' &&
        (searchType === 'Exact Match' ? (
          <div
            className="highlight-text mt-1 text-14 text-pl_txt_light_2 dark:text-pl_txt_dark_3"
            dangerouslySetInnerHTML={{
              __html: reformatMarkSentences(snippetText, stopwords) || '',
            }}
          ></div>
        ) : (
          <div
            className="highlight-text mt-1 text-14 text-pl_txt_light_2 dark:text-pl_txt_dark_3"
            dangerouslySetInnerHTML={{
              __html: reformatMarkSentences(highlightText, stopwords) || '',
            }}
          ></div>
        ))}
    </div>
  );
};

function displayNameOfParties(nameOfParties) {
  let textToDisplay = '';

  if (nameOfParties && nameOfParties.length > 0) {
    if (nameOfParties.length <= 250) {
      return nameOfParties;
    }

    const vsIndex = nameOfParties.search(/vs./i);

    if (vsIndex > 0) {
      const beforeVs = nameOfParties.substring(0, Math.min(vsIndex, 50)).trim();
      const afterVs = nameOfParties.substring(vsIndex + 3, vsIndex + 53).trim();
      textToDisplay = `${beforeVs} . . . Vs. ${afterVs} . . .`;
    } else {
      textToDisplay = nameOfParties.substring(0, 100);
    }
  }

  if (textToDisplay.match(/^[^a-zA-Z]/)) {
    textToDisplay = textToDisplay.replace(/^[^a-zA-Z]+/, '');
  }

  return textToDisplay;
}

function reformatMarkSentences(text, stopwords) {
  let sentencesWithMark = getSentencesWithMark(text);
  let reformattedSentences = sentencesWithMark.map((sentence) =>
    removeMarkIfStopword(sentence, stopwords)
  );

  let sentenceMarkCount = reformattedSentences.map((sentence) => {
    let markMatches = sentence.match(/<mark>(.*?)<\/mark>/g) || [];
    let uniqueWords = new Set(
      markMatches.map((match) =>
        match.replace('<mark>', '').replace('</mark>', '').toLowerCase()
      )
    );

    return { sentence, markCount: uniqueWords.size };
  });

  sentenceMarkCount.sort((a, b) => b.markCount - a.markCount);
  let reformatted = sentenceMarkCount.slice(0, 5).map((item) => item.sentence);

  return getSentencesWithMark(reformatted.join('. ')).join(' . . .');
}

function getSentencesWithMark(text) {
  let sentences = text.split('.');
  let sentencesWithMark = sentences.filter((sentence) =>
    sentence.includes('<mark>')
  );
  return sentencesWithMark;
}

function removeMarkIfStopword(markSentence, stopwords) {
  let markMatches = markSentence.match(/<mark>(.*?)<\/mark>/g);
  if (!markMatches) {
    return markSentence;
  }

  let markWords = markMatches.map((word) =>
    word.replace('<mark>', '').replace('</mark>', '')
  );

  for (let word of markWords) {
    if (stopwords.includes(word.trim().toLowerCase())) {
      markSentence = markSentence.replace(`<mark>${word}</mark>`, word);
    }
  }
  return markSentence;
}

export default Hit;
