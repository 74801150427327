import React, { useEffect, useRef, useState } from "react";
import SearchLayout from "../../layouts/SearchLayout";
import { useDispatch } from "react-redux";
import { homeActions } from "../../store/store";
import {
  Configure,
  Hits,
  Stats,
  CurrentRefinements,
  ClearRefinements,
} from "react-instantsearch";
import Hit from "./Hit/Hit";
import CustomSortBy from "../../hooks/customSortBy";
import RefinementListCard from "./RefinementListCard/RefinementListCard";
import CustomPagination from "../../hooks/customPagination";
import { getIndexSize } from "../../services/search-services";
import ResultsSkeleton from "./ResultsSkeleton";
import { useNavigate } from "react-router-dom";

const Results = () => {
  const dispatch = useDispatch();
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const [isLoading, setIsLoading] = useState(true);
  const [indexSize, setIndexSize] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const hiddenLayer = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(homeActions.setCurrentPage("search"));

    const fetchData = async () => {
      const fetchedSize = await getIndexSize();
      setIndexSize(parseInt(fetchedSize));
      if (fetchedSize !== null) {
        setIsLoading(false);
      }
    };

    fetchData();
    setTimeout(() => {
      const urlString = sessionStorage.getItem("urlString");
      if (urlString) {
        const newUrl = `/search${urlString.toString()}`;

        window.location.href = newUrl;
        sessionStorage.removeItem("urlString");
      }
    }, 2000);
  }, [dispatch, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleFilters = () => {
    setIsFilterOpen((prev) => !prev);
  };

  return (
    <div className="relative">
      <div
        ref={hiddenLayer}
        className={`fixed inset-0 bg-black bg-opacity-30 dark:bg-opacity-50 z-[140] duration-300 ${
          isFilterOpen ? "block opacity-100" : "hidden opacity-0"
        }`}
        onClick={toggleFilters}
      ></div>

      <SearchLayout isResults={true}>
        <div className="w-full">
          {isLoading ? (
            <ResultsSkeleton />
          ) : (
            <div className="flex flex-col py-20 pt-[180px] md:pt-[120px] max-w-6xl w-full">
              <div className="px-10 mb-8 md:px-20 flex flex-nowrap w-full overflow-x-auto py-1">
                <CurrentRefinements
                  classNames={{
                    root: "flex whitespace-nowrap",
                    noRefinementRoot: "",
                    noRefinementList: "",
                    item: "",
                    label: "hidden",
                    category:
                      "mx-0.5 rounded-full py-0.5 px-2 text-13 transition-all dark:text-pl_txt_dark_1 text-pl_txt_light_1 bg-pl_bg_light_1 dark:bg-pl_bg_dark_3 border dark:border-pl_border_dark_1 border-pl_border_light_1",
                    categoryLabel: "me-1",
                    list: "flex flex-nowrap",
                  }}
                />
                <ClearRefinements
                  translations={{
                    resetButtonText: "Clear all",
                  }}
                  classNames={{
                    root: "",
                    button:
                      "whitespace-nowrap mx-0.5 rounded-full py-0.5 px-2 text-13 transition-all dark:text-pl_txt_dark_1 text-pl_txt_light_1 bg-pl_bg_light_2 dark:bg-pl_bg_dark_2 border dark:border-pl_border_dark_1 border-pl_border_light_1",
                    disabledButton: "hidden",
                  }}
                />
              </div>

              <Configure hitsPerPage={15} />
              <div className="flex w-full md:gap-50 text-12 md:text-14 items-center ">
                <div className="flex flex-grow w-3/4 px-6 dark:text-pl_txt_dark_3 text-pl_txt_light_3">
                  <Stats
                    translations={{
                      rootElementText(nbHits) {
                        let statsText = "";
                        if (nbHits.nbHits === 0) {
                          statsText = "No results";
                        } else if (nbHits.nbHits === 1) {
                          statsText = "1 result";
                        } else {
                          statsText = `${nbHits.nbHits} results`;
                        }
                        return `${statsText.toLocaleString()} found ${
                          indexSize && nbHits.nbHits !== indexSize
                            ? ` - Searched ${indexSize.toLocaleString()} decisions`
                            : ""
                        } in ${nbHits.processingTimeMS} ms.`;
                      },
                    }}
                  />
                </div>
                <div className="flex justify-start items-center gap-10 mr-25 md:mr-0 sm:gap-30 sm:w-1/4">
                  <CustomSortBy indexName="paralegal" />
                  {!isDesktop && (
                    <button
                      className="md:hidden flex text-pl_prim_color_1"
                      type="button"
                      aria-controls="filters-section"
                      aria-expanded={isFilterOpen}
                      onClick={toggleFilters}
                    >
                      <svg
                        width="14"
                        height="18"
                        viewBox="0 0 14 18"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g>
                          <path d="M9.83232 16.1503H11.499V17.817H9.83232M6.49899 16.1503H8.16565V17.817H6.49899M3.16565 16.1503H4.83232V17.817H3.16565M9.83232 14.3836C9.86565 14.6336 9.78232 14.9003 9.59065 15.0753C9.26565 15.4003 8.74065 15.4003 8.41565 15.0753L5.07399 11.7336C4.88232 11.542 4.79899 11.2836 4.83232 11.042V6.77529L0.840653 1.66696C0.55732 1.30862 0.623986 0.783622 0.98232 0.500289C1.14065 0.383622 1.31565 0.316956 1.49899 0.316956H13.1657C13.349 0.316956 13.524 0.383622 13.6823 0.500289C14.0407 0.783622 14.1073 1.30862 13.824 1.66696L9.83232 6.77529V14.3836ZM3.19899 1.98362L6.49899 6.20029V10.8003L8.16565 12.467V6.19196L11.4657 1.98362H3.19899Z" />
                        </g>
                      </svg>
                    </button>
                  )}
                </div>
              </div>

              <div className="flex w-full md:gap-50">
                <div className="md:w-3/4 w-full flex flex-col justify-between h-full">
                  <div className="mt-2 flex-1">
                    <Hits hitComponent={Hit} />
                  </div>
                  <div className="mt-50">
                    <CustomPagination />
                  </div>
                </div>

                {isDesktop && (
                  <div className="flex w-1/4 mt-40 px-10">
                    <RefinementListCard />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        {!isDesktop && (
          <div
            id="filters-section"
            className={`fixed top-0 pb-50 right-0 max-w-full h-full bg-pl_bg_light_3 dark:bg-pl_bg_dark_1 z-[150] shadow-lg transform transition-transform duration-300 border-l dark:border-pl_border_dark_1 border-pl_border_light_1 ${
              isFilterOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="flex p-10 pr-20 justify-between items-center bg-pl_bg_light_1 dark:bg-pl_bg_dark_3 border-y dark:border-pl_border_dark_1 border-pl_border_light_1">
              <div className="text-16 text-pl_txt_light_2 dark:text-pl_txt_dark_1 font-medium">
                Filters
              </div>
              <button
                className="text-12 px-15 py-0.5 rounded-25 bg-pl_prim_color_1 text-white border border-pl_prim_color_1"
                onClick={toggleFilters}
              >
                Save
              </button>
            </div>
            <div className="h-full overflow-y-auto">
              <RefinementListCard isMobile={true} />
            </div>
          </div>
        )}
      </SearchLayout>
    </div>
  );
};

export default Results;
