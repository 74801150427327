import { typesenseServerConfig } from './typesense-server-config';
import { SearchClient as TypesenseSearchClient } from 'typesense';

const TYPESENSE_SERVER_CONFIG = typesenseServerConfig();
const INDEX_NAME = process.env.REACT_APP_TYPESENSE_COLLECTION_NAME;

export async function getIndexSize() {
  const typesenseSearchClient = new TypesenseSearchClient(
    TYPESENSE_SERVER_CONFIG
  );
  const results = await typesenseSearchClient
    .collections(INDEX_NAME)
    .documents()
    .search({ q: '*' });

  return results['found'];
}
