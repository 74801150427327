import React, { useEffect } from 'react';
import SearchLayout from '../../layouts/SearchLayout';
import SearchBar from '../../components/searchbar/SearchBar';
import { useDispatch } from 'react-redux';
import Logo from '../../components/Logo/Logo';
import { homeActions } from '../../store/store';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(homeActions.setCurrentPage('home'));
    dispatch(homeActions.setSelectedOption('All'));
    sessionStorage.setItem('searchType', 'All');
    sessionStorage.setItem('isEmpty', true);
    sessionStorage.removeItem('searchedQuery');
  }, [dispatch]);

  const handleClick = () => {
    navigate('/search');
  };

  return (
    <SearchLayout showSearch={false} showLogo={false}>
      <div className="flex flex-col items-center justify-center px-20 w-full">
        <div className="flex justify-center">
          <Logo />
        </div>

        <div className="flex w-full justify-center my-10 z-10">
          <SearchBar />
        </div>

        <div className="z-0 max-w-[800px] w-full justify-between flex animate-pulse dark:text-pl_txt_dark_1 dark:font-extralight bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] text-transparent bg-clip-text uppercase mt-20 m-30 sm:mt-50 sm:mb-60 text-12 text-center px-20 md:px-30">
          <span>f</span>
          <span>a</span>
          <span>s</span>
          <span>t</span>
          <span className="font-semibold mx-1">·</span>
          <span>c</span>
          <span>u</span>
          <span>r</span>
          <span>r</span>
          <span>e</span>
          <span>n</span>
          <span>t</span>
          <span className="font-semibold mx-1">·</span>
          <span>r</span>
          <span>e</span>
          <span>l</span>
          <span>e</span>
          <span>v</span>
          <span>a</span>
          <span>n</span>
          <span>t</span>
        </div>

        <button
          onClick={handleClick}
          className="z-0 max-w-[200px] w-full h-[40px] rounded-full bg-transparent text-pl_prim_color_1 border-pl_prim_color_1 border font-medium text-16 hover:border-0 hover:text-white hover:bg-gradient-to-br hover:from-[#dd0c7e] hover:via-[#ca1582] hover:to-[#a6208e] transition duration-300 ease-in-out"
        >
          Explore All
        </button>
      </div>
    </SearchLayout>
  );
};

export default Home;
