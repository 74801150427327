import { useAuthContext } from '@asgardeo/auth-react';
import React, { useState } from 'react';

function SigninButton() {
  const [isClicked, setIsClicked] = useState(false);
  const { signIn } = useAuthContext();

  const handleClick = () => {
    setIsClicked(!isClicked);
    const urlString = window.location.search;
    if (urlString) {
      sessionStorage.setItem('urlString', urlString);
    }
    signIn();
  };

  return (
    <div className="flex flex-col items-center gap-4 whitespace-nowrap">
      <button
        onClick={handleClick}
        className={`
          ${
            isClicked
              ? 'bg-transparent text-pl_prim_color_1 border border-pl_prim_color_1'
              : 'text-white bg-gradient-to-br from-[#dd0c7e] via-[#ca1582] to-[#a6208e] hover:bg-gradient-to-br hover:from-[#a6208e] hover:via-[#ca1582] hover:to-[#dd0c7e] transition duration-500 ease-in-out'
          } rounded-25 text-14 px-15 h-30
        `}
      >
        Sign in
      </button>
    </div>
  );
}

export default SigninButton;
