import React from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/MainLayout";

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <MainLayout>
      <div className="flex flex-col justify-center mx-auto max-w-6xl p-20 text-pl_txt_light_4 dark:text-pl_txt_dark_3 text-justify">
        <div className="flex flex-col gap-20 justify-center items-center">
          <div>
            <svg
              width="34"
              height="53"
              viewBox="0 0 34 53"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.33488 43.8695C3.11888 43.8695 2.11088 43.5015 1.31088 42.7655C0.542875 41.9975 0.158875 41.0535 0.158875 39.9335C0.158875 38.8135 0.542875 37.8855 1.31088 37.1495C2.11088 36.3815 3.11888 35.9975 4.33488 35.9975C5.51888 35.9975 6.49488 36.3815 7.26288 37.1495C8.03088 37.8855 8.41488 38.8135 8.41488 39.9335C8.41488 41.0535 8.03088 41.9975 7.26288 42.7655C6.49488 43.5015 5.51888 43.8695 4.33488 43.8695ZM4.33488 23.9975C3.11888 23.9975 2.11088 23.6295 1.31088 22.8935C0.542875 22.1255 0.158875 21.1815 0.158875 20.0615C0.158875 18.9415 0.542875 18.0135 1.31088 17.2775C2.11088 16.5095 3.11888 16.1255 4.33488 16.1255C5.51888 16.1255 6.49488 16.5095 7.26288 17.2775C8.03088 18.0135 8.41488 18.9415 8.41488 20.0615C8.41488 21.1815 8.03088 22.1255 7.26288 22.8935C6.49488 23.6295 5.51888 23.9975 4.33488 23.9975ZM25.5843 52.9895C22.4803 49.9815 20.0643 46.1415 18.3363 41.4695C16.6403 36.7975 15.7923 31.7895 15.7923 26.4455C15.7923 20.9735 16.6883 15.8375 18.4803 11.0375C20.2723 6.20547 22.8803 2.25348 26.3043 -0.818523H33.0723V-0.194526C29.5523 3.16547 26.8643 7.19748 25.0083 11.9015C23.1843 16.6055 22.2723 21.4535 22.2723 26.4455C22.2723 31.3095 23.1363 36.0135 24.8643 40.5575C26.6243 45.0695 29.1203 48.9895 32.3523 52.3175V52.9895H25.5843Z"
                fill="#DF0D7D"
              />
            </svg>
          </div>
          <div className="font-semibold text-[32px]">404 - Page Not Found</div>
          <div className="font-normal max-w-lg mx-20 flex text-center italic">
            Whoops! Looks like you took a wrong turn. The page you’re searching
            for must be on vacation. 🏖️
          </div>
          <button
            onClick={() => navigate("/")}
            className="max-w-[200px] w-full h-[40px] rounded-full bg-transparent text-pl_prim_color_1 border-pl_prim_color_1 border font-medium text-16 hover:border-0 hover:text-white hover:bg-gradient-to-br hover:from-[#dd0c7e] hover:via-[#ca1582] hover:to-[#a6208e] transition duration-300 ease-in-out"
          >
            Return to Home
          </button>
        </div>
      </div>
    </MainLayout>
  );
};

export default PageNotFound;
