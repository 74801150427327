import { useMenu } from 'react-instantsearch';

export function MenuSelect(props) {
  const { items, refine } = useMenu(props);

  const selectedValue = items.find((item) => item.isRefined)?.value || '';

  // const isPageNum = props.attribute === 'page_num';
  const isVolumeNum = props.attribute === 'volume_num';

  const defaultOption = {
    value: '',
    label: isVolumeNum ? 'All volumes' : 'All pages',
  };

  return (
    <select
      className="menu-select bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 text-13 border dark:border-pl_border_dark_1 border-pl_border_light_1 rounded-md outline-none"
      value={selectedValue}
      onChange={(event) => {
        refine(event.target.value);
      }}
    >
      <option className="text-12" key="default" value={defaultOption.value}>
        {defaultOption.label}
      </option>
      {items.map((item, index) => (
        <option className="text-12" key={index} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
  );
}
