import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeActions } from "../../../store/store";

function DropDown({ size }) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.home.selectedOption);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    dispatch(homeActions.setSelectedOption(option));
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const getIcon = (option, color) => {
    switch (option) {
      case "Default Search":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            className={`fill-current ${
              color === "#B91C8ACC" ? "text-[#B91C8ACC] dark:text-gray-300" : ""
            }`}
            fillOpacity="0.6"
          >
            <path d="M17 22V20H20V17H22V20.5C22 20.89 21.84 21.24 21.54 21.54C21.24 21.84 20.89 22 20.5 22H17ZM7 22H3.5C3.11 22 2.76 21.84 2.46 21.54C2.16 21.24 2 20.89 2 20.5V17H4V20H7V22ZM17 2H20.5C20.89 2 21.24 2.16 21.54 2.46C21.84 2.76 22 3.11 22 3.5V7H20V4H17V2ZM7 2V4H4V7H2V3.5C2 3.11 2.16 2.76 2.46 2.46C2.76 2.16 3.11 2 3.5 2H7ZM10.5 6C13 6 15 8 15 10.5C15 11.38 14.75 12.2 14.31 12.9L17.57 16.16L16.16 17.57L12.9 14.31C12.2 14.75 11.38 15 10.5 15C8 15 6 13 6 10.5C6 8 8 6 10.5 6ZM10.5 8C9.12 8 8 9.12 8 10.5C8 11.88 9.12 13 10.5 13C11.88 13 13 11.88 13 10.5C13 9.12 11.88 8 10.5 8Z" />
          </svg>
        );
      case "Exact Search":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            className={`fill-current ${
              color === "#B91C8ACC" ? "text-[#B91C8ACC] dark:text-gray-300" : ""
            }`}
            fillOpacity="0.6"
          >
            <path d="M15.5 12C18 12 20 14 20 16.5C20 17.38 19.75 18.21 19.31 18.9L22.39 22L21 23.39L17.88 20.32C17.19 20.75 16.37 21 15.5 21C13 21 11 19 11 16.5C11 14 13 12 15.5 12ZM15.5 14C14.837 14 14.2011 14.2634 13.7322 14.7322C13.2634 15.2011 13 15.837 13 16.5C13 17.163 13.2634 17.7989 13.7322 18.2678C14.2011 18.7366 14.837 19 15.5 19C16.163 19 16.7989 18.7366 17.2678 18.2678C17.7366 17.7989 18 17.163 18 16.5C18 15.837 17.7366 15.2011 17.2678 14.7322C16.7989 14.2634 16.163 14 15.5 14ZM5 3H19C20.11 3 21 3.89 21 5V13.03C20.5 12.23 19.81 11.54 19 11V5H5V19H9.5C9.81 19.75 10.26 20.42 10.81 21H5C3.89 21 3 20.11 3 19V5C3 3.89 3.89 3 5 3ZM7 7H17V9H7V7ZM7 11H12.03C11.23 11.5 10.54 12.19 10 13H7V11ZM7 15H9.17C9.06 15.5 9 16 9 16.5V17H7V15Z" />
          </svg>
        );
      case "AI Search":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            className={`fill-current ${
              color === "#B91C8ACC" ? "text-[#B91C8ACC] dark:text-gray-300" : ""
            }`}
            fillOpacity="0.6"
          >
            <path d="M9 4L11.5 9.5L17 12L11.5 14.5L9 20L6.5 14.5L1 12L6.5 9.5L9 4ZM9 8.83L8 11L5.83 12L8 13L9 15.17L10 13L12.17 12L10 11L9 8.83ZM19 9L17.74 6.26L15 5L17.74 3.75L19 1L20.25 3.75L23 5L20.25 6.26L19 9ZM19 23L17.74 20.26L15 19L17.74 17.75L19 15L20.25 17.75L23 19L20.25 20.26L19 23Z" />
          </svg>
        );
      case "Case No. Search":
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
            className={`fill-current ${
              color === "#B91C8ACC" ? "text-[#B91C8ACC] dark:text-gray-300" : ""
            }`}
            fillOpacity="0.6"
          >
            <path d="M4 17V9H2V7H6V17H4ZM22 15C22 16.11 21.1 17 20 17H16V15H20V13H18V11H20V9H16V7H20C20.5304 7 21.0391 7.21071 21.4142 7.58579C21.7893 7.96086 22 8.46957 22 9V10.5C22 10.8978 21.842 11.2794 21.5607 11.5607C21.2794 11.842 20.8978 12 20.5 12C20.8978 12 21.2794 12.158 21.5607 12.4393C21.842 12.7206 22 13.1022 22 13.5V15ZM14 15V17H8V13C8 11.89 8.9 11 10 11H12V9H8V7H12C12.5304 7 13.0391 7.21071 13.4142 7.58579C13.7893 7.96086 14 8.46957 14 9V11C14 12.11 13.1 13 12 13H10V15H14Z" />
          </svg>
        );
      default:
        return null;
    }
  };

  return (
    <div className="relative z-10 inline-block dark:text-pl_txt_dark_3 text-pl_txt_light_3 whitespace-nowrap">
      <button className="flex justify-center items-center" onClick={toggleMenu}>
        <div className="flex items-center gap-2">
          {getIcon(selectedOption, "#B91C8ACC")}
          <div
            className={`whitespace-nowrap text-pl_prim_color_2 dark:text-pl_txt_dark_3`}
          >
            {selectedOption}
          </div>
          <div>
            <svg
              className="fill-current"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
              fillOpacity="0.3"
            >
              <path d="M5.83331 8.40031L9.99998 12.567L14.1666 8.40031H5.83331Z" />
            </svg>
          </div>
        </div>
      </button>

      {isOpen && (
        <div
          ref={menuRef}
          className="absolute text-14 left-0 top-11 bg-pl_bg_light_3 dark:bg-pl_bg_dark_3 rounded-10 shadow-md border border-pl_border_light_1 dark:border-pl_border_dark_1 overflow-hidden"
        >
          <button
            className="flex items-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={() => handleOptionSelect("Default Search")}
          >
            <div>{getIcon("Default Search", "#000000B2")}</div>
            <div className="ml-2">Default Search</div>
          </button>
          <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" />
          <button
            className="flex items-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={() => handleOptionSelect("Exact Search")}
          >
            <div>{getIcon("Exact Search", "#000000B2")}</div>
            <div className="ml-2">Exact Search</div>
          </button>
          <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" />
          <button
            className="flex items-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={() => handleOptionSelect("AI Search")}
          >
            <div>{getIcon("AI Search", "#000000B2")}</div>
            <div className="ml-2">AI Search</div>
          </button>
          <hr className="border-t border-pl_border_light_1 dark:border-pl_border_dark_1" />
          <button
            className="flex items-center w-full px-3 py-2 text-left hover:text-pl_prim_color_1 hover:bg-pl_bg_light_1 hover:dark:bg-pl_bg_dark_4"
            onClick={() => handleOptionSelect("Case No. Search")}
          >
            <div>{getIcon("Case No. Search", "#000000B2")}</div>
            <div className="ml-2">Case No. Search</div>
          </button>
        </div>
      )}
    </div>
  );
}

export default DropDown;
