import React, { useEffect, useState } from "react";
import SigninButton from "./SigninButton/SigninButton";
import MenuCard from "./MenuCard/MenuCard";
import Tabs from "./Tabs/Tabs";
import UserProfile from "./UserProfile/UserProfile";
import Logo from "../Logo/Logo";
import SearchBar from "../searchbar/SearchBar";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "@asgardeo/auth-react";

const Header = ({ showLogo = true, showSearch = true, showMenu = true }) => {
  const currentPage = useSelector((state) => state.home.currentPage);
  const navigate = useNavigate();
  const { state } = useAuthContext();
  const [hideLogoRow, setHideLogoRow] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const threshold = 60;
      const shouldHideLogoRow = scrollY > threshold;

      if (shouldHideLogoRow !== hideLogoRow) {
        setHideLogoRow(shouldHideLogoRow);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hideLogoRow]);

  return (
    <div
      className={`${
        currentPage === "home"
          ? "bg-pl_bg_light_3 dark:bg-pl_bg_dark_1"
          : "bg-pl_bg_light_1 dark:bg-pl_bg_dark_3 border-y dark:border-pl_border_dark_1 border-pl_border_light_1 fixed"
      } w-full z-[100]`}
    >
      {/* Header Row */}
      <div
        className={`flex flex-wrap items-center p-10 px-10 md:px-20 ${
          hideLogoRow && "md:flex-nowrap"
        }`}
      >
        {/* Left: Logo */}
        {showLogo && (
          <div
            className={`${
              hideLogoRow ? "hidden md:flex" : "flex"
            } items-center flex-none pb-20 md:pb-0 `}
          >
            <button
              onClick={() => navigate("/")}
              className="flex items-center gap-2"
            >
              <Logo small />
            </button>
          </div>
        )}

        {/* Middle: Search Box */}
        {showSearch && (
          <div className="flex-grow order-2 md:order-none w-full md:w-auto md:ml-10">
            <SearchBar size="small" />
          </div>
        )}

        {showMenu && (
          <div
            className={`${
              hideLogoRow ? "hidden md:flex" : "flex"
            } gap-3 items-center ml-auto pb-20 md:pb-0 pl-10`}
          >
            <MenuCard />
            {state.isAuthenticated ? <UserProfile /> : <SigninButton />}
          </div>
        )}
      </div>
      {showSearch && (
        <div
          className={`${
            hideLogoRow ? "hidden" : "block"
          } xl:ml-[220px] xl:w-[100vh-220px]`}
        >
          <Tabs />
        </div>
      )}
    </div>
  );
};

export default Header;
